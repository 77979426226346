/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
    z-index:1000;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        z-index:1000;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, th{border:0;font-family:inherit;font-size:100%;font-style:inherit;font-weight:inherit;margin:0;outline:0;padding:0;vertical-align:baseline;}
html{font-size:62.5%;overflow-y:scroll;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;}
*,
*:before,
*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
body{background:#000000;}
article{margin: 0 auto}
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section{display:block;}
ol, ul{list-style:none;}
table{border-collapse:separate;border-spacing:0;}
caption, th, td, th{font-weight:normal;text-align:left;}
blockquote:before, blockquote:after,
q:before, q:after{content:"";}
blockquote, q{quotes:"" "";}
a:focus{outline:thin dotted;}
a:hover,
a:active{outline:0;}
a img{border:0;}
* html{font-size:100%;}
html{font-size:22px;line-height:1.5em;}



body,
button,
input,
select,
textarea{color:#404040;font-family:Georgia, "Bitstream Charter", serif;font-size:22px;font-weight:400;}
h1, h2, h3, h4, h5, h6{clear:both;}
hr{border:1px solid #ccc;border-width:0 0 1px 0;height:0;margin-top:-1px;margin-bottom:24px;padding-top:24px;}
p{margin-bottom:24px;}
ul, ol{margin:0 0 24px 36px;}
ul{list-style:disc;}
ol{list-style:decimal;}
li > ul,
li > ol{margin-bottom:0;margin-left:24px;}
dt{font-weight:600;}
dd{margin:0 24px 24px;}
b, strong{font-weight:600;}
dfn, cite, em, i{font-style:italic;}
blockquote{margin:0 24px;}
address{margin:0 0 24px;}
pre{background:rgba(119, 119, 119, 0.5);font-family:"Courier 10 Pitch", Courier, monospace;font-size:0.9375em;line-height:1.6em;margin-top:-2px;margin-bottom:24px;max-width:100%;overflow:auto;padding:24px;}
code, kbd, tt, var{font-family:Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;font-size:0.875em;line-height:1.71429em;}
abbr, acronym{border-bottom:1px dotted #666;cursor:help;}
mark, ins{background:#fff9c0;text-decoration:none;}
sup,
sub{font-size:75%;height:0;line-height:0;position:relative;vertical-align:baseline;}
sup{bottom:1ex;}
sub{top:.5ex;}
small{font-size:75%;}
big{font-size:125%;}
figure{margin:0;}
table{margin:0 0 24px;width:100%;}
th{font-weight:bold;}
img{}
body{background:#000000;} 
.fen{float:left;margin:0px;overflow:hidden;width:295px;height:295px;position:relative;}
.button200x40{float:left;margin:0px;overflow:hidden;width:200px;height:40px;position:relative;}
.header-fen{margin:0px;padding:0px;position:absolute;top:0px;background:transparent none repeat scroll 0% 0%;border:0px none;transition:left 0.3s ease-in-out 0s;z-index:1;display:block;}
.thumb-fen{background-position:center center;background-repeat:no-repeat;background-size:cover;position:absolute;left:0px;top:0px;width:100%;height:100%;z-index:0;}
.entry-meta-fen{background:rgba(5, 112, 168, 0.5) none repeat scroll 0% 0%;color:#FFF;display:block;float:left;font-family:"Open Sans Condensed",Helvetica,Arial,sans-serif;font-style:normal;font-weight:bold;margin:0px;font-size:0.75em;line-height:2em;padding:5px 15px;text-align:left;text-transform:uppercase;width:auto;}
.entry-title-fen{max-width:191px;font-size:1.25em;background:rgba(0, 0, 0, 0.5) none repeat scroll 0% 0%;clear:both;display:block;float:left;line-height:1.25;font-weight:bold;margin:0px;max-width:191px;overflow:hidden;padding:7.5px 15px;position:relative;z-index:1;text-align:left;}
.header-fen:link{color:#FF0000;}
.header-fen:visited{color:#00FF00;}
.header-fen:hover header{left:0;-moz-transition:left 0.3s ease-in-out;-webkit-transition:left 0.3s ease-in-out;transition:left 0.3s ease-in-out;}

.rcorners3 {
  border-radius: 15px;
  background: url(images/bg/brickwall.png);
  background-size: 200px;
  background-position: left top;
  background-repeat: repeat;
  padding: 20px;

}

#cover {position: fixed; height: 100%; width: 100%; top:0; left: 0; background: #000; z-index:9999;}
.responsive {min-width: 100%;max-width: 100%;height: auto;}
.rainbow_text_anim {
    text-align: center;
    text-decoration: underline;
    font-size: 32px;
    font-family: monospace;
    letter-spacing: 5px;
}
.rainbow_text_animated_side {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_text_animation 6s ease-in-out infinite;
    background-size: 400% 100%;
}

@font-face {
    font-family: japan;
    src: url("fonts/japan.otf") format("opentype");
}

.japan_dvd {

    color: black;
font-size: 32px;
font-weight: bold;
      /*background: -webkit-linear-gradient(#eee, #333);*/
        background: linear-gradient(0deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 3px;
      -webkit-text-stroke-color: white;
        -webkit-text-stroke-width: 1px;
      
}

.test_dvd {
font-family: 'japan';
  font-variant: small-caps;
  font-size: 50px;
  font-weight: 800;
  text-align: center;
  -webkit-background-clip: text;
}

.test_dvd  {
  color: rgb(255, 255, 255);
  background-image: linear-gradient(
    rgb(255, 60, 255) 46%,
    rgb(255, 60, 167) 49%,
    rgb(255, 60, 249) 80%
  );
  background-image: rgb(255, 255, 255);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 4px rgb(0, 0, 0);
}

.test_dvd::before {
  /*background: -webkit-linear-gradient(
    -86deg,
    #eef85b 5%,
    #7aec8d 53%,
    #09e5c3 91%
  );*/
  background: rgb(255, 255, 255);
  -webkit-background-clip: text;
  -webkit-text-stroke: 8px transparent;
  color: #232d2d;
}

.test_dvd::before {
  content: attr(data-text);
  position: absolute;
  z-index: -1;
}

.zoom {
  transition: transform .2s; /* Animation */
  display: inline-block;
}

.zoom:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}


@keyframes rainbow_text_animation {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}
