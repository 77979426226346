$backgroundColor: #ffffff;
$bodyColor: #000000;
$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
}


// Our variables
$base-font-family: "Open Sans Condensed",Helvetica,Arial,sans-serif;
$base-font-size:   22px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       white;
$background-color: black;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);


$content-width: 1920px;

$on-palm:          600px;
$on-laptop:        800px;


// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}


// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "theme",
        "base",
        "layout",
        "syntax-highlighting";
